
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { VueCookieNext } from 'vue-cookie-next';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import TargetListModal from '@/layout/header/partials/association/TargetListDrawer.vue';
import TargetChangeSummeryModal from '@/layout/header/partials/association/TargetChangeSummeryDrawer.vue';

import OrganizationModal from '@/components/modals/forms/settings/OrganizationModal.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';

export default defineComponent({
  name: 'new-entity-course',
  components: {
    TargetListModal,
    TargetChangeSummeryModal,
    Form,
    Datatable,
    Field,
  },
  data() {
    return {
      actionApprove: false,
      entity: {
        tranche_id: '',
        entity_info_id: '',
      },
      targetList: [],
      association: [],
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Association',
          key: 'association',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Target Year Id',
          key: 'year_id',
          sortable: true,
        },
        {
          name: 'Target Year From',
          key: 'targetYearFrom',
          sortable: true,
        },
        {
          name: 'Target Year To',
          key: 'targetYearTo',
          sortable: true,
        },
        {
          name: 'Trainee Number',
          key: 'traineeNumber',
          sortable: true,
        },
        {
          name: 'Requested by',
          key: 'requested_by',
          sortable: true,
        },
        {
          name: 'Request Date Time',
          key: 'requested_date_time',
          sortable: true,
          width: '150px',
        },
      ],
      load: true,
      tableData: [],
      loading: false,
      courseInfoData: false,
      showtargetList: false,
      actionEdit: false,
      actionReject: false,
      lists: [],
      search: '',
      componentKey: 0,
      actionDelete: false,

      tranche: [],
    };
  },
  async created() {
    await this.targetDataList();
    await this.actioncheck();
  },
  methods: {
    async targetDataList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }

      await ApiService.get(
        'entity/target/pending-list?entity_info_id=' + entity_id
      )
        .then((response) => {
          this.showtargetList = true;
          this.load = false;
          this.targetList = response.data.data;
          console.log(response.data.data);
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    async actioncheck() {
      console.log('action check ');
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Training Institute') {
            console.log(menu[i].action);
            let actions = menu[i].action;
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                console.log('edit');
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Reject') {
                this.actionReject = true;
              }
              if (actions[j].action_name === 'Delete') {
                this.actionDelete = true;
              }
            }
          }
        }
      }
    },

    Approve(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('entity/target/approve/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('target-updated', true);
              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Reject(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to reject it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Rejected!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('entity/target/status_change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('target-updated', true);
              Swal.fire('Reject!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Deleted!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('entity/target/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('target-updated', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    edit(data) {
      this.emitter.emit('edit-modal-data', data);
    },
    view(data) {
      this.emitter.emit('view-modal-data', data);
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
